import React from "react";
import "./landingPageHero.css";
import { Zoom, JackInTheBox } from "react-awesome-reveal";

function LandingPageHero() {
  return (
    <section className="landing-page-hero">
      <Zoom triggerOnce={true} duration={1000}>
        <p className="landing-header">JONAS PEDERSEN</p>
      </Zoom>
      <JackInTheBox triggerOnce={true} duration={1000}>
        <p className="landing-subheader">PORTFOLIO</p>
      </JackInTheBox>
    </section>
  );
}

export default LandingPageHero;
