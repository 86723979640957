import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

function MobileNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setActive] = useState("home");

  // Function to handle logo click
  const handleLogoClick = (e) => {
    if (location.pathname === "/") {
      setActive("home");
      e.preventDefault(); // Prevent the default navigation
      window.location.reload(); // Reload the page
    }
  };

  const handleClick = (destination) => {
    navigate("/" + destination);
  };

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActive(path || "home");
  }, [location]);

  return (
    <div className="navbar" id="navbar-mobile">
      <nav className="MobileNavigation">
        <div className="mobile-logo">
          <Link to="/" onClick={handleLogoClick}>
            <img
              src="images/jonasPedersen.svg"
              className="mobile-logo"
              alt="logo variant 1"
            />
          </Link>
        </div>
        <p className="navtext">PORTFOLIO</p>
        <nav className="navlinks">
          <ul>
            <li>
              <button
                className={` ${isActive === "work" ? "active-link" : ""}`}
                onClick={() => handleClick("work")}
              >
                WORK
              </button>
            </li>
            <li>
              <button
                className={` ${isActive === "contact" ? "active-link" : ""}`}
                onClick={() => handleClick("contact")}
              >
                CONTACT
              </button>
            </li>
            <li>
              <button
                className={` ${isActive === "info" ? "active-link" : ""}`}
                onClick={() => handleClick("info")}
              >
                INFO
              </button>
            </li>
          </ul>
        </nav>
      </nav>
    </div>
  );
}

export default MobileNavigation;
