import React from "react";
import "./Info.css";
import { Zoom } from "react-awesome-reveal";

function LandingPageHero() {
  return (
    <section className="info">
      <Zoom triggerOnce={true} duration={1000}>
        <div className="info-text">
          <p>
            I HAVE A BACHELOR IN DIGITAL DESIGN AND INTERACTIVE TECHNOLOGIES AS
            WELL AS AN MASTER IN SOFTWARE DESIGN. WITH SOLID EXPERIENCE IN
            INTERACTION DESIGN, UI/UX, ALGORITHM DESIGN, AND PROGRAMMING (JAVA,
            PYTHON, HTML, CSS, JAVASCRIPT, F#), I COMBINE TECHNICAL SKILLS WITH
            A CREATIVE APPROACH TO PROBLEM-SOLVING. I HAVE A STRONG
            UNDERSTANDING OF PROCESSES AND PRODUCT DEVELOPMENT AND THRIVE IN
            INTERDISCIPLINARY TEAMS, WHERE I CAN CONTRIBUTE TO BOTH THE
            TECHNICAL AND CREATIVE ASPECTS OF PROJECTS.
          </p>
          <p>
            ALONGSIDE MY STUDIES, I HAVE WORKED AS A FREELANCE MUSIC PRODUCER
            AND SOUND DESIGNER, WITH MY PRODUCTIONS HAVING BEEN PLAYED ON DR P3.
            I HAVE ALSO CONTRIBUTED AS A SOUND DESIGNER FOR A DOCUMENTARY
            PROJECT THAT WAS LATER SOLD TO TV2. IN ADDITION, I HAVE TAUGHT MUSIC
            AS PART OF MY FREELANCE WORK.
          </p>
          <p>
            I AM PASSIONATE ABOUT CREATING SOLUTIONS THAT COMBINE TECHNOLOGY AND
            CREATIVITY, AND I AM ALWAYS LOOKING FOR NEW CHALLENGES WHERE I CAN
            BRING MY INTERDISCIPLINARY SKILLS INTO PLAY — WHETHER IN INTERACTION
            DESIGN, SOFTWARE DEVELOPMENT, SOUND DESIGN, OR A COMBINATION OF ALL
            THREE.
          </p>
        </div>
      </Zoom>
    </section>
  );
}

export default LandingPageHero;
