import React from "react";
import WorkItem from "../../Components/WorkItem/WorkItem";

function UxUi() {
  return (
    <section className="work-ux-ui">
      <h1 className="work-section-header">UI/UX DESIGN</h1>
      <div className="work-content">
        <WorkItem
          path={"images/safeSpace1.png"}
          alt={"Landing page of Safe Space"}
          header={"WEB APPLICATION DESIGN"}
          description={
            "This project is called Safe Space. It is an university project aimed at facillitating therapy groups online. Through the project, I did user research, low-fidelity prototyping in Miro, high-fidelity prototyping in Figma, user-testing, and finally, I implemented the design in React. It contains a working real-time chat connected to back4app. Thus, I acted as a full-stack developer for this project."
          }
          direction={"/safespaceimages"}
          cursorClass={"withCursor"}
        />
        <WorkItem
          path={"images/zoePortfolio.png"}
          alt={"Landing page of zoenattestad.dk"}
          header={"PORTFOLIO DESIGN"}
          description={
            "Together with Zoe, we designed her professional portfolio in Figma, and then, I implemented it in React. We tried to balance information with minimalistic and sleek design. Check out the result!"
          }
          direction={"https://zoenattestad.dk/"}
          cursorClass={"withCursor"}
        />
      </div>
    </section>
  );
}

export default UxUi;
