import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Carousel.css";

const Carousel = ({ data }) => {
  const [slide, setSlide] = useState(0);
  const navigate = useNavigate();

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  function BackToWork() {
    navigate("/work");
  }

  return (
    <div className="carousel">
      <div className="carousel-content">
        <button onClick={prevSlide} className="prev-next">
          PREV
        </button>
        <button onClick={nextSlide} className="prev-next">
          NEXT
        </button>
      </div>
      {data.map((item, idx) => {
        return (
          <img
            src={item.src}
            alt={item.alt}
            key={idx}
            className={slide === idx ? "slide" : "slide slide-hidden"}
          />
        );
      })}
      <button onClick={BackToWork} className="prev-next">
        ← BACK TO WORK
      </button>

      <span className="indicators">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx ? "indicator" : "indicator indicator-inactive"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>
    </div>
  );
};

export default Carousel;
