import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";

function DesktopNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setActive] = useState("home");

  // Function to handle logo click
  const handleLogoClick = (e) => {
    if (location.pathname === "/") {
      setActive("home");
      e.preventDefault(); // Prevent the default navigation
      window.location.reload(); // Reload the page
    }
  };

  const handleClick = (destination) => {
    navigate("/" + destination);
  };

  useEffect(() => {
    const path = location.pathname.substring(1); // Remove leading slash
    setActive(path || "home"); // Default to "home" if path is empty
  }, [location]);

  return (
    <div className="navbar" id="navbar-desktop">
      <nav className="DesktopNavigation">
        <div className="logo">
          <Link to="/" onClick={() => handleLogoClick()}>
            <img
              src="images/logo1.svg"
              className="no-hover"
              alt="logo variant 1"
              width="276px"
              height="29.99px"
            />
            <img
              src="images/logo2.svg"
              className="hover"
              alt="logo variant 2"
              width="276px"
              height="29.99px"
            />
          </Link>
        </div>
        <nav className="navlinks-right">
          <ul>
            <li>
              <button
                className={` ${isActive === "contact" ? "active-link" : ""}`}
                onClick={() => handleClick("contact")}
              >
                CONTACT
              </button>
            </li>
          </ul>
        </nav>
        <nav className="navlinks-left">
          <ul>
            <li>
              <button
                className={` ${isActive === "work" ? "active-link" : ""}`}
                onClick={() => handleClick("work")}
              >
                WORK
              </button>
            </li>
            <li>
              <button
                className={` ${isActive === "info" ? "active-link" : ""}`}
                onClick={() => handleClick("info")}
              >
                INFO
              </button>
            </li>
          </ul>
        </nav>
      </nav>
    </div>
  );
}

export default DesktopNavigation;
