import React from "react";
import "./SafeSpaceImages.css";
import Carousel from "../../Components/Carousel/Carousel";
import carouselData from "../../data/carouselData.json";

function SafeSpaceImages() {
  return (
    <div className="safe-space-images">
      <Carousel data={carouselData.slides} />
    </div>
  );
}

export default SafeSpaceImages;
