import React from "react";
import YoutubeEmbed from "../../Components/YouTubeEmbedded/YouTubeEmbedded";
import WorkItemText from "../../Components/WorkItem/WorkItemText";

function SoundAndMusic() {
  return (
    <section className="work-sound-and-music">
      <h1 className="work-section-header">SOUND & MUSIC</h1>
      <div className="youtube-content">
        <div className="youtube-item">
          <YoutubeEmbed embedId="wvXQ9tZgyak?si=wXZjzdj1Ynkx4qjT" />
          <WorkItemText
            header={"mp.oxford: Dage I Sol"}
            description={<i>Sound Engineer // Producer // Songwriter</i>}
          />
        </div>
        <div className="youtube-item">
          <YoutubeEmbed embedId="Blc6AIW73GY?si=r5n74iBtbV6JZtN7" />
          <WorkItemText
            header={"SPYDER: Frankenstein"}
            description={
              <i>Sound Engineer // Producer // Songwriter // Mix // Master</i>
            }
          />
        </div>
      </div>
      <div className="youtube-content">
        <div className="youtube-item">
          <YoutubeEmbed embedId="v9YhHcJTBA4?si=swJv6xuTUpgRWyLA" />
          <WorkItemText
            header={"SPYDER: Swim Away"}
            description={<i>Sound Engineer // Producer // Songwriter // Mix</i>}
          />
        </div>
        <div className="youtube-item">
          <YoutubeEmbed embedId="qrydhcWSj1I?si=p5q6KTBFAIrApa5B" />
          <WorkItemText
            header={"mp.oxford: Hvad Er Det Værd?"}
            description={<i>Sound Engineer // Producer</i>}
          />
        </div>
      </div>
    </section>
  );
}

export default SoundAndMusic;
