import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/navbar/Navbar";
import LandingPageHero from "./Pages/landingPage/LandingPageHero";
import Info from "./Pages/info/Info";
import Work from "./Pages/work/Work";
import Contact from "./Components/Contact/Contact";
import SafeSpaceImages from "./Pages/SafeSpaceImages/SafeSpaceImages";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPageHero />} />
        <Route path="/info" element={<Info />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/safespaceimages" element={<SafeSpaceImages />} />
      </Routes>
    </>
  );
}

export default App;
