import React from "react";
import "./Work.css";
import UxUi from "./UxUi";
import SoftwareDesign from "./SoftwareDesign";
import SoundAndMusic from "./SoundAndMusic";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import { Zoom } from "react-awesome-reveal";

function Work() {
  return (
    <>
      <UxUi />
      <SoftwareDesign />
      <SoundAndMusic />
      <Zoom duration={1000}>
        <ScrollToTopButton />
      </Zoom>
    </>
  );
}

export default Work;
