import React from "react";
import WorkItemImage from "./WorkItemImage";
import WorkItemText from "./WorkItemText";

function WorkItem({ path, alt, header, description, direction, cursorClass }) {
  return (
    <article className="work-item">
      <WorkItemImage
        path={path}
        alt={alt}
        direction={direction}
        cursorClass={cursorClass}
      />
      <WorkItemText header={header} description={description} />
    </article>
  );
}

export default WorkItem;
