import React from "react";
import "./workItem.css";

function WorkItemText({ header, description }) {
  return (
    <div className="work-item-text">
      <h2 className="work-item-header">{header}</h2>
      <p className="work-item-description">{description}</p>
    </div>
  );
}

export default WorkItemText;
