import React from "react";
import "./Contact.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "8f286ac0-2a39-4281-9091-615f70654b54");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Success!",
        text: "Message sent successfully!",
        icon: "success",
        customClass: {
          confirmButton: "my-custom-button",
        },
      }).then(() => {
        setTimeout(() => {
          navigate("/");
        }, 0);
      });
    }
  };

  return (
    <section className="contact">
      <form onSubmit={onSubmit} id="form1">
        <h2>CONTACT FORM</h2>
        <div className="input-box">
          <label>FULL NAME</label>
          <input
            type="text"
            className="field"
            placeholder="Enter your name"
            name="name"
            required
          />
        </div>
        <div className="input-box">
          <label>E-MAIL ADRESS</label>
          <input
            type="email"
            className="field"
            placeholder="Enter your email"
            name="email"
            required
          />
        </div>
        <div className="input-box">
          <label>YOUR MESSAGE</label>
          <textarea
            name="message"
            placeholder="Enter your message"
            required
            className="field mess"
          ></textarea>
        </div>
        <button type="submit">SEND MESSAGE</button>
      </form>
    </section>
  );
}

export default Contact;
