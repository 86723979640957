import React from "react";
import "./workItem.css";
import { useNavigate } from "react-router-dom";

function WorkItemImage({ path, alt, direction, cursorClass }) {
  const navigate = useNavigate();

  function handleOnClick() {
    console.log("Image clicked!");
    if (direction.startsWith("http")) {
      window.open(direction, "_blank");
    } else {
      navigate(direction);
    }
  }

  return (
    <div className="work-item-image">
      <img
        src={path}
        alt={alt}
        onClick={handleOnClick}
        className={cursorClass}
      />
    </div>
  );
}

export default WorkItemImage;
